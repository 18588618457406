import './main.scss'
import p1 from './p1.png'
import p2 from './p2.png'
import p3 from './p3.png'

import i1 from './i1.png'
import i2 from './i2.png'
import i3 from './i3.png'
import i4 from './i4.png'
import i5 from './i5.png'
import i6 from './i6.png'
import i7 from './i7.png'
import i8 from './i8.png'

import u1 from './u1.png'
import u2 from './u2.png'
import u3 from './u3.png'
import star from './starIco.svg'


export const MainPage = () => {
    const services = [
        {
            img: i1,
            title: 'Проектирование'
        },
        {
            img: i2,
            title: 'Пуско-наладка'
        },
        {
            img: i3,
            title: 'Монтаж'
        },
        // {
        //     img: i4,
        //     title: 'рекуперация'
        // },{
        //     img: i5,
        //     title: 'кондеционнирование'
        // },{
        //     img: i6,
        //     title: 'теплоснабжение'
        // },
        {
            img: i7,
            title: 'обслужевание'
        },
        // {
        //     img: i8,
        //     title: 'Холодоснабщение'
        // },
    ]
    return (
        <>
            <section className={'firsBlock'}>
                <h1 className="title">Вентиляция для вашего комфорта</h1>
                <form className={'consult_form'}>
                    <p className="form_title">Закажите консультацию</p>
                    <input className={'input'} placeholder={"Ваше имя"} type="text"/>
                    <input className={'input'} placeholder={"Номер телефона"} type="tel"/>
                    <div className="row">
                        <input className={'btn'} value={'Заказать звонок'} type="submit"/>
                        <p className="note">пн-пт, 9:00 - 20:00</p>
                    </div>
                </form>
            </section>

            <section className="our-production">
                <h2 className="title-block">Наша продукция</h2>
                <div className="row justify">
                    <div className="product">
                        <img src={p1} alt="" className="product-img"/>
                        <p className="product-name">Приточно-вытяжные установки с рекуперацией</p>
                    </div>
                    <div className="product">
                        <img src={p2} alt="" className="product-img"/>
                        <p className="product-name">приточные и вытяжные установки</p>
                    </div>
                    <div className="product">
                        <img src={p3} alt="" className="product-img"/>
                        <p className="product-name">Кондиционеры</p>
                    </div>
                </div>
            </section>

            <section className="our-serv">
                <h1 className="title-block">Наши услуги</h1>
                <div className="services-grid">
                    {services.map(serv => (
                        <div className="service">
                            <img src={serv.img} alt="" className="icon"/>
                            <p className="service-name">{serv.title}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section className={'firsBlock block2'}>
                <h1 className="title">Узнайте актуальные цены</h1>
                <form className={'consult_form center'}>
                    <input className={'input'} placeholder={"Ваш email"} type="email"/>
                    <input className={'btn'} value={'Узнать цены'} type="submit"/>
                </form>
            </section>

            {/*<section className="our-production">*/}
            {/*    <h2 className="title-block">Наша продукция</h2>*/}
            {/*    <div className="row justify">*/}
            {/*        <div className="product">*/}
            {/*            <img src={p1} alt="" className="product-img"/>*/}
            {/*            <p className="product-name">приточные установки</p>*/}
            {/*            <p className="product-desc">Спешите воспользоваться нашей акцией до конца 2020 года. Это реально выгодно!</p>*/}
            {/*            <a href="#" className="link">Подробнее</a>*/}
            {/*        </div>*/}
            {/*        <div className="product">*/}
            {/*            <img src={p2} alt="" className="product-img"/>*/}
            {/*            <p className="product-name">ПРИТОЧНО-ВЫТЯЖНЫЕ C ТЕПЛОВЫМ НАСОСОМ</p>*/}
            {/*            <p className="product-desc">Техобслуживание вентиляции представляет собой спектр работ, направленный на...</p>*/}
            {/*            <a href="#" className="link">Подробнее</a>*/}
            {/*        </div>*/}
            {/*        <div className="product">*/}
            {/*            <img src={p3} alt="" className="product-img"/>*/}
            {/*            <p className="product-name">ПРИТОЧНО-ВЫТЯЖНЫЕ C РЕКУПЕРАЦИЕЙ ТЕПЛА</p>*/}
            {/*            <p className="product-desc">Проектирование кондиционирования и холодоснабжения заключается...</p>*/}
            {/*            <a href="#" className="link">Подробнее</a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <a href="#" className="btn center mt">узнать больше</a>*/}
            {/*</section>*/}

            <section className="reviews">
                <p className="title-block">Отзывы клиентов</p>
                <div className="row justify">
                    <div className="coment transp">
                        <img src={u1} alt="" className="user-icon"/>
                        <p className="username">Дмитрий</p>
                        <p className="user-text">Сделали отличную систему вентилиции в частном доме с учетом местлрасположения, оборудования. Все четко и теперь очень удобно пользоваться.
                        </p>
                        <div className="row stars">
                            {
                                [...Array(5)].map(stars => (
                                    <>
                                    <img src={star} alt=""/></>
                                ))
                            }
                        </div>
                    </div>
                    <div className="coment">
                        <img src={u2} alt="" className="user-icon"/>
                        <p className="username">Александр</p>
                        <p className="user-text">Специалисты провели полный рассчет нашей в ситуации, помогли выбрать достаточное количество оборудования. Уже 5 лет прошло, все отлично. Рекомендую.
                        </p>
                        <div className="row stars">
                            {
                                [...Array(5)].map(stars => (
                                    <>
                                    <img src={star} alt=""/></>
                                ))
                            }
                        </div>
                    </div>
                    <div className="coment transp">
                        <img src={u3} alt="" className="user-icon"/>
                        <p className="username">Генадий</p>
                        <p className="user-text">Сделали отличную систему вентилиции в частном доме с учетом местлрасположения, оборудования. Все четко и теперь очень удобно пользоваться.
                        </p>
                        <div className="row stars">
                            {
                                [...Array(5)].map(stars => (
                                    <>
                                    <img src={star} alt=""/></>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className={'firsBlock block3 row'}>
                <h1 className="title">Запишитесь на консультацию</h1>
                <form className={'consult_form center align-center'}>
                    <input className={'input'} placeholder={"Ваш телефон"} type="tel"/>
                    <input className={'btn'} value={'Записаться на консультацию'} type="submit"/>
                </form>
            </section>
        </>
    )
}