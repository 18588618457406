import './footer.scss'
export const Footer = () => {
    return (
        <footer>

            <div className="note">
                *Вся представленная на сайте информация, касающаяся технических характеристик, наличия на складе, стоимости товаров, носит информационный характер и ни при каких условиях не является публичной офертой.
            </div>
            <div className="note">
                Ventilyacia.BY, 2020© Copyright
            </div>
        </footer>
    )
}
