import './catalog.scss'
export const Catalog = () => {
    const products = [
        {
            name: 'Название товара',
            desc: 'Описание товара',
            img: 'https://24maxshop.eu/image/cache/catalog/Wanas/imgcms415vv-2000x2000.jpg'
        },{
            name: 'Название товара',
            desc: 'Описание товара',
            img: 'https://24maxshop.eu/image/cache/catalog/Wanas/imgcms415vv-2000x2000.jpg'
        },{
            name: 'Название товара',
            desc: 'Описание товара',
            img: 'https://24maxshop.eu/image/cache/catalog/Wanas/imgcms415vv-2000x2000.jpg'
        },{
            name: 'Название товара',
            desc: 'Описание товара',
            img: 'https://24maxshop.eu/image/cache/catalog/Wanas/imgcms415vv-2000x2000.jpg'
        },{
            name: 'Название товара',
            desc: 'Описание товара',
            img: 'https://24maxshop.eu/image/cache/catalog/Wanas/imgcms415vv-2000x2000.jpg'
        },{
            name: 'Название товара',
            desc: 'Описание товара',
            img: 'https://24maxshop.eu/image/cache/catalog/Wanas/imgcms415vv-2000x2000.jpg'
        },
    ]
    return (
        <section className="firsBlock">
            <h1 className="title">Раздел в разработке</h1>
        </section>
    )
    return (
        <>
            <section className="firsBlock">
                <h1 className="title">Не упустите наши акции и новости</h1>
                <form className={'consult_form'}>
                    <a href="/" className="btn w100">Новости и акции</a>
                </form>
            </section>

            <section className="cards-grid">
                {products.map(product => (
                    <div className={'prod'}>
                        <img src={product.img} alt="" className="prod-img"/>
                        <p className="prod-title">{product.name}</p>
                        <p className="prod-desc">{product.desc}</p>
                    </div>
                ))}
            </section>
        </>
    )
}
