import {Route, Routes} from "react-router-dom";
import {MainPage} from "./pages/main";
import {Header} from "./components/header";
import {Footer} from "./components/footer";
import {Catalog} from "./pages/catalog";
import {Services} from "./pages/services";
import {News} from "./pages/news";
import {About} from "./pages/about";

function App() {
  return (
    <>
        <Header/>
        <nav>
            <a href="/" className="nav">Главная</a>
            <a href="/catalog" className="nav">Каталог</a>
            <a href="/services" className="nav">Услуги</a>
            <a href="/news" className="nav">Новости</a>
            <a href="/about" className="nav">О нас</a>
        </nav>
        <Routes>
            <Route path={'/'} element={<MainPage />}/>
            <Route path={'/catalog'} element={<Catalog />}/>
            <Route path={'/services'} element={<Services />}/>
            <Route path={'/news'} element={<News />}/>
            <Route path={'/about'} element={<About />}/>
        </Routes>
        <Footer/>
    </>
  );
}

export default App;
