import './services.scss'
import img1 from './1.jpg'
import img2 from './2.jpg'
import img3 from './3.jpeg'
import img4 from './4.png'
export const Services = () => {
    const services = [
        {
            name: 'Вентиляция',
            desc: 'описание услуги',
            img: img1
        },{
            name: 'кондиционирование',
            desc: 'описание услуги',
            img: img2
        },{
            name: 'фитостены',
            desc: 'описание услуги',
            img: img3
        },
        {
            name: 'центральное пылеудаление',
            desc: 'описание услуги',
            img: img4
        }
    ]
    return (
        <div className={'servies-list'}>
            {services.map(serv => (
                <div className={'serv'}>
                    <img src={serv.img} alt="" className="serv-img"/>

                    <div className="texts-serv">
                        <div>
                            <h1 className="serv-title">{serv.name}</h1>
                            <p className="serv-desc">{serv.desc}</p>
                        </div>
                        <a href="/" className="btn">заказать консультацию</a>
                    </div>

                </div>
            ))}
        </div>
    )
}
