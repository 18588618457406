import './news.scss'
export const News = () => {
    const news = [
        {
            name: 'Новость',
            desc: 'Каждый из нас понимает очевидную вещь: социально-экономическое развитие способствует подготовке и реализации экспериментов, поражающих по своей масштабности и грандиозности. Современные технологии достигли такого уровня, что постоянное информационно-пропагандистское обеспечение нашей деятельности выявляет срочную потребность вывода текущих активов. Предварительные выводы неутешительны: глубокий уровень погружения обеспечивает широкому кругу (специалистов) участие',
            img: 'https://24maxshop.eu/image/cache/catalog/Wanas/imgcms415vv-2000x2000.jpg'
        },{
            name: 'Новость',
            desc: 'Каждый из нас понимает очевидную вещь: социально-экономическое развитие способствует подготовке и реализации экспериментов, поражающих по своей масштабности и грандиозности. Современные технологии достигли такого уровня, что постоянное информационно-пропагандистское обеспечение нашей деятельности выявляет срочную потребность вывода текущих активов. Предварительные выводы неутешительны: глубокий уровень погружения обеспечивает широкому кругу (специалистов) участие',
            img: 'https://24maxshop.eu/image/cache/catalog/Wanas/imgcms415vv-2000x2000.jpg'
        },
    ]
    return (
        <section className="firsBlock">
            <h1 className="title">Раздел в разработке</h1>
        </section>
    )
    return (
        <div className={'news-list'}>
            {news.map(New => (
                <div className={'new'}>
                    <img src={New.img} alt=""/>

                    <div>
                        <h1>{New.name}</h1>
                        <p>{New.desc}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
