import './about.scss'

import i1 from './1.jpg'
import i3 from './3.jpg'
import i4 from './4.jpg'
import i5 from './5.jpg'
import i6 from './6.jpg'
import i7 from './7.jpg'
import i8 from './8.jpg'
import i9 from './9.jpg'
import i10 from './10.jpg'
import i11 from './11.jpg'
import i12 from './12.jpg'
import i13 from './13.jpg'
import i14 from './14.jpg'
import i15 from './15.jpg'
import i16 from './16.jpg'
import i17 from './17.jpg'
import i18 from './18.jpg'
export const About = () => {
    const images = [i1, i3, i4, i5,i6,i7,i8,i9,i10,i11,i12,i13,i14,i15,i16,i17,i18]

    return (
        <>
            <section className={'firsBlock block2'}>
                <h1 className="title">Гарантия качества и надежности</h1>
                <form className={'consult_form '}>
                    <a href="#" className="btn w100">консультация</a>
                </form>
            </section>

            <p className="title-block">Немного о нас</p>
            <p className="desription">
                динамично развивающаяся компания, осуществляющая полный спектр услуг для вентиляции и кондиционирования «под ключ».
                Являясь дилером различных производителей имеем возможность поставлять и устанавливать экологичные воздухообрабатывающие установки произво¬дительностью от 300 до 100 000 м3/ч, холодильные машины: чиллеры, фанкойлы, VRF-системы, канальное венти-ляционное оборудование и прочее, а также предоставлять готовые решения.
                Мы предлагаем лучшее оборудование и материалы, благодаря чему всегда уверены в качестве. Гарантии на оборудование и материалы 2-5 лет, работы - 5 лет.
            </p>


            <p className="title-block mt">Примеры работ</p>
            <div className="block-center">
            <div className="photo-grid">
                {images.map(im => (
                    <img src={im} alt=""/>
                ))}
            </div></div>
        </>
    )
}
