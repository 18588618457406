import Logo from '../../media/logo.png'
import './header.scss'
import tg from './tg.svg'
import viber from './viber.svg'
export const Header = () => {
    return (
        <header>
            <div className="logo">
                <img src={Logo} className={'logo-img'} alt=""/>
                <div className="logo-name">
                    <p className="site-title">Вентиляция и оборудование</p>
                    <p className="site-desc">Всё для обустройства вентиляции</p>
                </div>
            </div>

            <div className="info">
                <div className="column">
                    <p className="info-el">+375 29 635-35-53 (звонки до 20:00)</p>
                    <p className="info-el">+375 29 677-56-80 (звонки до 20:00)</p>
                    <p className="info-el">zakaz@ventilyacia.by</p>
                </div>
                <div className="column">
                    <a className={'phone tg'} href="https://t.me/derzavaclimate"><img src={tg} alt="" className="social-icon"/>+375 29 635-35-53</a>
                    <a className={'phone viber'} href="#"><img src={viber} alt="" className="social-icon"/>+375 29 635-35-53</a>
                </div>
                <div className="column">
                    <p className="info-el">ул.Пономаренко,35А, оф.701/3</p>
                    <p className="info-el">пн-пт, 9:00 - 17:30</p>
                </div>
            </div>
        </header>
    )
}
